<section>
    <section class="banner-pages banner-bg-blog" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1 style="font-size: 50px;">News</h1>

                    <h2>&nbsp;</h2>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

</section><!-- banner -->
<section id="intro" class="section">
    <div class="container">
        <div class="intro">
            <span class="site-navigation"><a [routerLink]="['/']">Home</a> » Blog</span>
        </div>
    </div>
</section><!-- intro ends-->

<section id="blog" class="no-padding">
  
    <!-- 1 -->
    <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="../../../assets/images/cutmdf2.jpg"
                        alt="Circular saw cutting through MDF sheet" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="268">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>How To Cut Your MDF Boards (Medium Density Fibreboards)</h3>
                <div class="date">Date: 26.11.2024, Author: CUTWRIGHTS</div>
                <p>
                    <a [routerLink]="['/blog/how-to-cut-mdf']" style="padding-left:20px;">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        Read more...
                    </a>

            </div>
        </div>
    </div>


    <!-- 2 -->
    <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="../../../assets/images/melamine1.png"
                        alt="*NEW RANGE INTRODUCTORY OFFER* - 30% OFF Kronospan MFC" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="268">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>How To Cut Melamine Boards The Correct Way</h3>
                <div class="date">Date: 26.11.2024, Author: CUTWRIGHTS</div>
                <p>
                    <a [routerLink]="['/blog/cutting-melamine-board']" style="padding-left:20px;">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        Read more...
                    </a>

            </div>
        </div>
    </div>
    <!-- 3 -->
    <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="../../../assets/images/type2.png"
                        alt="*NEW RANGE INTRODUCTORY OFFER* - 30% OFF Kronospan MFC" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="268">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>What Type Of MDF Should I Use For The Best Results
                </h3>
                <div class="date">Date: 26.11.2024, Author: CUTWRIGHTS</div>
                <p>
                    <a [routerLink]="['/blog/types-of-mdf-wood']" style="padding-left:20px;">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        Read more...
                    </a>

            </div>
        </div>
    </div>
    <!-- 4 -->
    <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="../../../assets/images/paint4.png"
                        alt="*NEW RANGE INTRODUCTORY OFFER* - 30% OFF Kronospan MFC" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="268">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>How To Paint Your MDF Boards The Right Way 
                </h3>
                <div class="date">Date: 26.11.2024, Author: CUTWRIGHTS</div>
                <p>
                    <a [routerLink]="['/blog/how-to-paint-mdf']" style="padding-left:20px;">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        Read more...
                    </a>

            </div>
        </div>
    </div>
    <!-- 5 -->
    <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="../../../assets/images/seal2.png"
                        alt="*NEW RANGE INTRODUCTORY OFFER* - 30% OFF Kronospan MFC" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="268">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>The Importance Of Sealing MDF Edges Correctly 
                </h3>
                <div class="date">Date: 26.11.2024, Author: CUTWRIGHTS</div>
                <p>
                    <a [routerLink]="['/blog/how-to-seal-mdf-edges']" style="padding-left:20px;">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        Read more...
                    </a>

            </div>
        </div>
    </div>
    <!-- 6 -->
    <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="../../../assets/images/correct2.png"
                        alt="*NEW RANGE INTRODUCTORY OFFER* - 30% OFF Kronospan MFC" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="268">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>How To Correctly Adjust Kitchen Cabinet Hinges
                </h3>
                <div class="date">Date: 26.11.2024, Author: CUTWRIGHTS</div>
                <p>
                    <a [routerLink]="['/blog/how-to-adjust-kitchen-cabinet-hinges']" style="padding-left:20px;">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        Read more...
                    </a>

            </div>
        </div>
    </div>
    <!-- 7 -->
    <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="../../../assets/images/kitchen1.png"
                        alt="*NEW RANGE INTRODUCTORY OFFER* - 30% OFF Kronospan MFC" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="268">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>What Hinges Should I Use For My Kitchen Cabinets
                </h3>
                <div class="date">Date: 26.11.2024, Author: CUTWRIGHTS</div>
                <p>
                    <a [routerLink]="['/blog/what-hinges-for-kitchen-cabinets']" style="padding-left:20px;">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        Read more...
                    </a>

            </div>
        </div>
    </div>
     <!-- 8 -->
    <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="../../../assets/images/tri1.png"
                        alt="*NEW RANGE INTRODUCTORY OFFER* - 30% OFF Kronospan MFC" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="268">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>Tricoya MDF: The Complete Beginners Guide 
                </h3>
                <div class="date">Date: 26.11.2024, Author: CUTWRIGHTS</div>
                <p>
                    <a [routerLink]="['/blog/what-is-tricoya-mdf']" style="padding-left:20px;">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        Read more...
                    </a>

            </div>
        </div>
    </div>
    <br>
</section>

<!-- newsletter sign up -->
<div style="display: none">
    <form action="/new/Account/AjaxLogin" autocomplete="off" class="popup" data-ajax="true" data-ajax-method="POST"
        data-ajax-success="onAjaxLoginSuccess" id="login_form" method="post" novalidate="novalidate">
        <h5 id="pop-login-error">Login to my cutwrights</h5>
        <p>Please fill the form below. If you forgot your password please <a id="popup-reset-password"
                href="#resetpassword_form">click here</a>.</p>
        <div id="loginAlertDiv">
        </div>
        <p>
            <label class="required" for="Email">E-mail</label>
            <input autocomplete="on" autofocus="autofocus" data-val="true" data-val-email="Invalid E-mail Address"
                data-val-required="The E-mail field is required." id="autofocusField" name="Email"
                placeholder="Probably your email address" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="Email" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label class="required" for="Password">Password</label>
            <input autocomplete="off" data-val="true" data-val-required="The Password field is required." id="Password"
                name="Password" placeholder="Your password" size="30" type="password">
            <span class="field-validation-valid" data-valmsg-for="Password" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label></label>
            <input id="loginButton" type="submit" value="Login" class="btn btn-large">
        </p>
        <p>
            <em></em>
        </p>
    </form>
</div>
<div style="display:none">
    <form action="/new/Account/ResetPassword" class="popup" data-ajax="true" data-ajax-method="POST"
        data-ajax-success="onResetPasswordSuccess" id="resetpassword_form" method="post" novalidate="novalidate">
        <h5 id="signup-error">Reset password</h5>
        <p>Please fill the form to receive a reset password email.</p>
        <div id="resetPasswordAlertDiv">
        </div>
        <p>
            <label class="required" for="Email">E-mail</label>
            <input autocomplete="on" autofocus="autofocus" data-val="true" data-val-email="Invalid E-mail Address"
                data-val-required="The E-mail field is required." id="autofocusField" name="Email"
                placeholder="Your e-mail address" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="Email" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>

        </p>
        <p>
            <label></label>
            <input type="submit" value="Send" class="btn btn-large">
        </p>
        <p>
            <em></em>
        </p>
    </form>
</div>
<div style="display: none">
    <form action="/new/Account/SignUp" autocomplete="off" class="popup" data-ajax="true" data-ajax-method="POST"
        data-ajax-success="onSignUpSuccess" id="signup_form" method="post" novalidate="novalidate">
        <h5 id="signup-error">Sign up</h5>
        <p>Please fill the form to receive an activation mail.</p>
        <div id="signUpAlertDiv">
        </div>
        <p>
            <label class="required" for="signupFirstName">First name:</label>
            <input autofocus="autofocus" data-val="true" data-val-required="First name is required." id="autofocusField"
                name="signupFirstName" placeholder="Your first name" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="signupFirstName" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label class="required" for="signupLastName">Last name:</label>
            <input data-val="true" data-val-required="Last name is required." id="signupLastName" name="signupLastName"
                placeholder="Your last name" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="signupLastName" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label for="signupCompany">Company:</label>
            <input id="signupCompany" name="signupCompany" placeholder="Your company name" size="30" type="text"
                value="">

        </p>
        <p>
            <label class="required" for="signupEmail">E-mail:</label>
            <input data-val="true" data-val-email="Invalid E-mail Address" data-val-required="Email is required."
                id="signupEmail" name="signupEmail" placeholder="Your email address" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="signupEmail" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label for="signupPhone">Phone Number:</label>
            <input id="signupPhone" name="signupPhone" placeholder="Your phone number" size="30" type="text" value="">
        </p>
        <p>
            <label for="signupAddress">Address:</label>
            <textarea cols="20" id="signupAddress" name="signupAddress" placeholder="Your address" rows="4"
                size="30"></textarea>
        </p>
        <p>
            <label for="signupCity">City:</label>
            <input id="signupCity" name="signupCity" placeholder="Your city" size="30" type="text" value="">
        </p>
        <p>
            <label class="required" for="signupPostcode">Postcode:</label>
            <input data-val="true" data-val-required="Postcode is required." id="signupPostcode" name="signupPostcode"
                placeholder="Your postcode" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="signupPostcode" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label class="required" for="signupPassword">Password:</label>
            <input data-val="true" data-val-required="Password is required." id="signupPassword" name="signupPassword"
                placeholder="Your password" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="signupPassword" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>

            <label for="signupType">Type:</label>
            <select id="signupType" name="signupType" style="width: 70%;">
                <option value="trade">Trade</option>
                <option value="retail">Retail</option>
            </select>
        </p>
        <p>
            <label for="signupTerms">Terms:</label>
            <input data-val="true" data-val-required="The Terms: field is required." id="signupTerms" name="signupTerms"
                type="checkbox" value="true"><input name="signupTerms" type="hidden" value="false"><span> I accept the
                terms &amp; conditions</span>
            <input id="signupTerms" name="signupTerms" type="hidden" value="False">
        </p>
        <p>
            <label for="signupQuotesNotification">Quotes notification:</label>
            <input checked="checked" data-val="true" data-val-required="The Quotes notification: field is required."
                id="signupQuotesNotification" name="signupQuotesNotification" type="checkbox" value="true"><input
                name="signupQuotesNotification" type="hidden" value="false"><span> I allow Cutwrights to send Quote
                Notifications</span>
            <input id="signupQuotesNotification" name="signupQuotesNotification" type="hidden" value="True">
        </p>
        <p>
            <label for="signupMarketing">Marketing:</label>
            <input checked="checked" data-val="true" data-val-required="The Marketing: field is required."
                id="signupMarketing" name="signupMarketing" type="checkbox" value="true"><input name="signupMarketing"
                type="hidden" value="false"><span> I allow Cutwrights to send Marketing Information</span>
            <input id="signupMarketing" name="signupMarketing" type="hidden" value="True">
        </p>
        <p>
            <a href="/new/Resources/cutwrights-term-and-condition.pdf" target="_blank">Terms &amp; Conditions</a> |
            <a href="/new/Resources/cutwrights-privacy.pdf" target="_blank">Privacy Policy</a>
        </p>
        <p>

            <label></label>
            <input type="submit" value="Sign up" class="btn btn-large">
        </p>
        <p>

            <em></em>
        </p>
    </form>


</div>