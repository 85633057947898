import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-blog-cutmdf',
  standalone: true,
  imports: [],
  templateUrl: './blog-cutmdf.component.html',
  styleUrl: './blog-cutmdf.component.scss'
})
export class BlogCutmdfComponent {
  constructor(private metadataService: MetadataService){
    
  }
  ngOnInit(): void {
    this.metadataService.setTitle('How To Cut Your MDF Boards (Medium Density Fibreboards) | Cutwrights');
    this.metadataService.setDescription('Discover the precise steps for cutting MDF boards flawlessly. Master the art of working with Medium Density Fibreboards with expert guidance');
  }

}
