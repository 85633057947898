import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-blog-tricoya',
  standalone: true,
  imports: [],
  templateUrl: './blog-tricoya.component.html',
  styleUrl: './blog-tricoya.component.scss'
})
export class BlogTricoyaComponent {
  constructor(private metadataService: MetadataService){
      
  }
  ngOnInit(): void {
    this.metadataService.setTitle('Tricoya MDF: The Complete Beginners Guide | Cutwrights ');
    this.metadataService.setDescription("Explore Tricoya MDF in our complete beginner's guide. Learn about its benefits, uses, and why it's a top choice for durability and performance");
  }

}
