import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-blog-mdftype',
  standalone: true,
  imports: [],
  templateUrl: './blog-mdftype.component.html',
  styleUrl: './blog-mdftype.component.scss'
})
export class BlogMdftypeComponent {
  constructor(private metadataService: MetadataService){
      
  }
  ngOnInit(): void {
    this.metadataService.setTitle('What Type Of MDF Should I Use For The Best Results | Cutwrights ');
    this.metadataService.setDescription('Discover the ideal MDF types for your project. Learn which MDF grade delivers the best results for durability and performance. Read more ');
  }

}
