import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Part, eStatusM } from 'src/app/models/cut-wrights.model';
import { Shaker, eShakerType } from 'src/app/models/shaker.model';
import { Address, User } from 'src/app/models/user.model';
import { DataService } from 'src/app/service/data-service';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { GetQuoteComponent } from '../get-quote/get-quote.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-quote-result',
  templateUrl: './quote-result.component.html',
  styleUrls: ['./quote-result.component.scss']
})
export class QuoteResultComponent implements OnInit {
  @Input() quote: any;
  @ViewChild('errorPopup', { static: true })
  errorPopup!: TemplateRef<any>;
  @ViewChild('forgotPassword', { static: true })
  forgotPassword!: TemplateRef<any>;
  fpEmail = ""
  submitted = false;
  isLoading = false;
  quoteId: string | null = '';
  cForm: FormGroup;
  eCodes = {};
  isLoggedIn = false;
  contactForm: FormGroup;
  loginForm: FormGroup;
  radioForm: FormGroup;
  user;
  weight = "0";
  parts = 0;
  address: Address[] = []
  edgeCodeMapping: { [key: string]: string; } = {};
  addressForm: FormGroup;
  bsModalRef!: BsModalRef;
  modalMessage: string="";
  modalHeader: string="";
  selected_adr!: Address;
  selectedIndex!:number;
  checkButton:boolean=false;
  postCodeValidity:boolean=false;
  cached_quote_id: any ='';
  cached_user_id: any ='';  
  oldQuoteId: string;
  oldAddress: Address | undefined;
  oldChoice: any;
  userString:string="";
  //2 way binding for Panel and Offcut
  panelsValue: boolean | null = null;
  offcutsValue: boolean | null = null;  
  constructor(private route: ActivatedRoute,private metadataService: MetadataService,private dataService: DataService,private apiService: EndpointApiService,
    private router: Router, private formBuilder: FormBuilder,private cookieService: CookieService, private getQuoteComponent : GetQuoteComponent,private modalService: BsModalService) {
      this.oldQuoteId = this.route.snapshot.paramMap.get('oldQuoteId') as string;
      this.route.queryParams.subscribe(params => {
        this.oldQuoteId = params['oldQuoteId'];    
      });
      this.addressForm = this.formBuilder.group({
      addressSelect: [''],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required]
    });
    
    this.radioForm = this.formBuilder.group({
      choice: ['', Validators.required]
    });
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    const token = localStorage.getItem('token');
  
    if (token) {
      this.isLoggedIn = true;
      this.userString = localStorage.getItem('user')!;
      const userJSON = JSON.parse(this.userString)
      if(userJSON.roles.includes("admin")){
        this.userString = localStorage.getItem('tempUser')!;
       }
      this.user = JSON.parse(this.userString)
      this.address = (this.user as User).address;
    }
    this.contactForm = this.formBuilder.group({
      displayName: ['', Validators.required],
      firstName: [''],
      lastName: [''],
      companyName:[''],
      postcode: [''],
      email: ['', [Validators.required, Validators.email]],
      address: [''],
      mobile: ['', Validators.required],
      city: [''],
      billingAddress: this.formBuilder.group({
        address: ['', [ Validators.maxLength(200)]],
        city: ['',],
        postcode: ['',Validators.required],
      }),      
      notifications: [true],
      marketing: [false],
    });
    this.createEdgeCodeDictionary();
    this.cForm = this.formBuilder.group({
      parts: [''],
      weight: [''],
      shakerDoors:[''],
      drawers:[''],
      panels: [null, Validators.required],
      offcuts: [null, Validators.required]
    })
    this.eCodes = {
      "PrepTapeRadius": "PR",
      "PrepTapeSquare": "PS",
      "0.4mm": "4R",
      "0.5mm": "5R",
      "0.8mm": "8R",
      "1.0mm": "1R",
      "1.0mm Side by Side": "1SBR",
      "1.3mm": "13R",
      "2.0mm Radius": "2R",
      "2.0mm Square": "2S",
      "3.0mm Radius": "3R",
      "3.0mm Square": "3S",
      "2.0mm 3D Acrylic Ply": "2R",
      "2.0mm 3D Acrylic Brush Steel": "2R"
    }
  }
  nameValidator() {
    const displayName = this.contactForm.get('displayName')?.value as string;
    const spaceIndex = displayName.indexOf(' ');
    if (spaceIndex === -1) {
      this.openModal(this.errorPopup,"Alert!","Please enter First Name and Last Name");
      return true;
    }
    const firstName = displayName.substring(0, spaceIndex);
    const lastName = displayName.substring(spaceIndex + 1);
    this.contactForm.get('firstName')?.setValue(firstName);
    this.contactForm.get('lastName')?.setValue(lastName);
  return false;
 }
  ngOnInit(): void {
    localStorage.setItem('my_quoteId',this.quote.quoteId)
    this.cached_quote_id =localStorage.getItem('my_quoteId')
    this.cached_user_id =localStorage.getItem('userId')
    if(this.isLoggedIn){
    this.apiService.getQuote(this.oldQuoteId).subscribe((data:any)=>{
      data.collection ? this.oldChoice="collection": this.oldChoice="delivery"
      this.oldAddress=data.address;
      //Fetch 
      
      // if(this.oldQuoteId){
      //   this.oldChoice==="collection" ? this.radioForm.get('choice')?.setValue('collection'): this.radioForm.get('choice')?.setValue('delivery');
      //   this.addressForm.get('address')?.setValue(this.oldAddress?.address)
      //   this.addressForm.get('city')?.setValue(this.oldAddress?.city)
      //   this.addressForm.get('postcode')?.setValue(this.oldAddress?.postcode)
      // }
    })
    }
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    this.cForm.get('weight')?.setValue(this.getWeight());
    this.cForm.get('parts')?.setValue(this.getPartsC());
    this.cForm.get('shakerDoors')?.setValue(this.getShakerDoorsC());
    this.cForm.get('drawers')?.setValue(this.getShakerDrawerC());
    this.cForm.get('panels')?.setValue(this.quote.panelsNeedsLabelling);
    this.cForm.get('offcuts')?.setValue(this.quote.includeOffCuts);    

    if (this.cached_quote_id=== this.quote.quoteId && this.cached_user_id== this.user.userId){
        if(localStorage.getItem('offcuts')==='true'){
          this.offcutsValue=true;
        }
        if(localStorage.getItem('offcuts')==='false'){
          this.offcutsValue=false;
        }
        if(localStorage.getItem('panels')==='true'){
          this.panelsValue=true;
        }
        if(localStorage.getItem('panels')==='false'){
          this.panelsValue=false;
        }

        if(localStorage.getItem('collection/delivery')){
          (localStorage.getItem('collection/delivery')==='collection') ?this.radioForm.get('choice')?.setValue('collection'): this.radioForm.get('choice')?.setValue('delivery')
        }

        if(localStorage.getItem('addressSelected') && localStorage.getItem('collection/delivery')=="delivery"){
          let addressObj=JSON.parse(localStorage.getItem('addressSelected')!);
          this.addressForm.get('address')?.setValue(addressObj?.address)
          this.addressForm.get('city')?.setValue(addressObj?.city)
          this.addressForm.get('postcode')?.setValue(addressObj?.postcode)
        }
    } 
  
    this.dataService.getPanelAndOffcut().subscribe((data) => {
      if (data === true && this.quote.panelsNeedsLabelling && this.quote.includeOffCuts) {
        this.cForm.get('panels')?.setValue(this.quote.panelsNeedsLabelling);
        this.cForm.get('offcuts')?.setValue(this.quote.includeOffCuts);
        this.panelsValue=this.quote.panelsNeedsLabelling;
        this.offcutsValue=this.quote.includeOffCuts;

      }
      else { 
        this.cForm.get('panels')?.setValue(null);
        this.cForm.get('offcuts')?.setValue(null);
      }
    });
    this.cForm.get('parts')?.disable();
    this.cForm.get('weight')?.disable();
    this.cForm.get('shakerDoors')?.disable();
    this.cForm.get('drawers')?.disable();
  }
  getQuote(): void {
    if (this.isLoggedIn == false) {
      this.onSignupSubmit();
      return;
    }
    const ad = this.addressForm.value
      delete ad.addressSelect;
      if(!(this.radioForm.get('choice')?.value == 'collection')){
          if(this.user.address && this.user.address.length > 0){
         //   if((this.user.address.some((ad:any) => ad.address = this.addressForm.value.address))){
          const existingIndex = this.user.address.findIndex((ad: { address: any; }) => ad.address === this.addressForm.value.address);
          if (existingIndex !== -1) {
            // Replace existing address
             this.user.address[existingIndex] = this.addressForm.value;
          } else {
              // Add new address
              this.user.address.push(this.addressForm.value);
          }
            // this.user.address.push(this.addressForm.value);
            //delete this.user.Id
            this.user.isLoggedIn = true;
            if(this.user.token){
            this.apiService.updateUser(this.user).subscribe(data => {
              if(this.user.roles.includes("admin")){
              localStorage.setItem('tempUser',JSON.stringify(data));
              }else{
                localStorage.setItem('user',JSON.stringify(data));
              }
            })
           }
          } else {
            this.user.address = []
            this.user.address.push(this.addressForm.value);
            //delete this.user.Id
            this.user.isLoggedIn = true;
            this.apiService.updateUser(this.user).subscribe(data => {
              if(this.user.roles.includes("admin")){
                localStorage.setItem('tempUser',JSON.stringify(data));
                }else{
                  localStorage.setItem('user',JSON.stringify(data));
                }
            })
          }
       
      }
      this.quote.jobId = 0,
      this.quote.includeOffCuts = this.cForm.value.offcuts,
      this.quote.panelsNeedsLabelling = this.cForm.value.panels, 
      this.quote.customerId = this.user.userId,
      this.quote.customerName=this.user.firstName + this.user.lastName,
      //this.quote.customerCompanyName=this.user.customerCompanyName,
      this.quote.optimisingparameter = "Holzma HPP380 STD",
      this.quote.sawparameter = "Holzma HPP380 STD",
      this.quote.numberOfParts = this.getPartsC(),
      this.quote.weight = this.getWeight(),
      this.quote.collection = this.radioForm.get('choice')?.value == 'collection' ? true : false,
      this.quote.address = ad,
      this.quote.status =1
      this.quote.customerMobileNumber = this.user.mobile
      this.isLoading = true;
      this.apiService.putCutWrightData(this.quote).subscribe((res: any) => {
        this.quoteId = res.quoteId;
        // this.cookieService.set('d_quote',"",0)
        //Clear LocalStorage of values 
        localStorage.removeItem('addressSelected')
        localStorage.removeItem('collection/delivery')
        localStorage.removeItem('offcuts')
        localStorage.removeItem('panels')
        this.submitted = true;
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      })
  }
  getPartsC(): number {
    var count = 0;
    this.quote.parts.forEach((elem: Part) => {
      count += Number(elem.quantity) * 1
    });
    return count;
  }
  getShakerDoorsC(){
    let count = 0
    this.quote.shakers?.forEach((sha:Shaker) =>{
      if(sha.type == eShakerType.ShakerDoor){
        count+=sha.quantity;
      }
    })
    return count;
  }
  getShakerDrawerC(){
    let count = 0
    this.quote.shakers?.forEach((sha:Shaker) =>{
      if(sha.type == eShakerType.ShakerDrawer || sha.type == eShakerType.PlainDrawer){
        count+=sha.quantity;
      }
    })
    return count;
  }
  getWeight() {
    var weight = 0;
    this.quote.parts.forEach((elem: Part) => {
      weight += Number(elem.weight);
    });
    return weight.toFixed(2);
  }
  createEdgeCodeDictionary() {
    this.edgeCodeMapping = {
      "true,true,true,true": "EAR$",
      "true,true,true,false": "2LE$SE$",
      "true,true,false,false": "LE$LE",
      "true,false,false,false": "LE$",
      "false,true,false,false": "LE$",
      "false,false,true,false": "SE$",
      "false,false,false,true": "SE$",
      "false,false,true,true": "2SE$",
      "false,true,true,true": "LE$2SE$",
      "false,true,false,true": "LE$SE$",
      "true,false,true,false": "LE$SE$",
      "false,true,true,false": "LE$SE$",
      "true,false,false,true": "LE$SE$",
      "true,false,true,true": "LE$2SE$",
      "true,true,false,true": "2LE$SE$"
    };
  }
  getEdgeCode(L1: any, L2: any, W1: any, W2: any) {
    const key = `${L1},${L2},${W1},${W2}`;
    return this.edgeCodeMapping[key]
  }
  onSignupSubmit() {
    if (this.contactForm.valid) {
      if(this.nameValidator()){
        //this.openModal();
        return;
      }
      const formData = this.contactForm.value;
      var ad = this.addressForm.value
      delete ad.addressSelect
      formData.address = [
        ad
      ]
      formData.address=this.contactForm.value.address
      formData.city= this.contactForm.value.city;
      if((this.radioForm.get('choice')?.value == 'collection') && this.isLoggedIn==false){  
        formData.address = [
          ad=formData.billingAddress
        ]      
        formData.postCode=ad.postcode
        formData.city= ad.city        
      }
      formData.displayName=formData.firstName;
      this.isLoading = true;
      this.apiService.addUser(formData).subscribe(data => {
        this.user = data;
        this.isLoggedIn=true;
        this.getQuote();
        //this.openModal(this.imagePopup);
        this.isLoading=true;
      }, (error) => {
        this.openModal(this.errorPopup,'Error','Please check your email Address.')
        this.isLoading = false;
      });
    }
  }
  onSubmit() {
    this.isLoading = true;
    this.apiService.login(this.loginForm.value).subscribe((data) => {
      this.user = data;
      this.address = (data as User).address;
      localStorage.setItem('token', (data as User).token);
      localStorage.setItem('user',JSON.stringify(data));
      if(this.user.roles[0]=="admin"){
        this.openModal(this.errorPopup,"Alert!","You have signed in as an Admin.");
      }
      this.isLoggedIn = true;
      this.isLoading = false;
      this.getQuoteComponent.listenToQuoteResult();
      SharedModule.refreshHeader();
    }, (error) => {
      this.isLoading = false;
    })
  }
  openModal(template: TemplateRef<any>, header:string, message:string): void {
    this.modalHeader=header;
    this.modalMessage = message;
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  clearOther(selectedChoice: string) {
    if (selectedChoice === 'collection') {
      this.radioForm.get('choice')?.setValue('collection');
    } else {
      this.radioForm.get('choice')?.setValue('delivery');
    }
  }
  checkPostCode() {
    const postcode = this.addressForm.get('postcode')?.value
    if(postcode){
      const normalizedPostcode = postcode.trim().replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase();
      this.apiService.getPostCodeAzure(normalizedPostcode).subscribe((data:any) =>{
        if(data["valid"]== false){
          this.addressForm.get('postcode')?.setErrors({'invalid':true})
        } else {
          this.addressForm.get('postcode')?.setErrors(null);
          //this.validateAddress(data["formatted_address"])
          //this.checkButton=false;        
        }
      });
    }
  }
  validateAddress(formatted_address:string){
    const postcode = this.addressForm.get('postcode')?.value
    const normalizedPostcode = postcode.trim().replace(/[^a-zA-Z0-9]/g, '').toUpperCase();    
    let splits = formatted_address.split(',')
    let address = splits[0]
    splits[splits.length - 2] =splits[splits.length - 2].trim()
    let city = splits[splits.length - 2].split(' ')[0]
    let address_f = this.addressForm.get('address')?.value
    let city_f = this.addressForm.get('city')?.value
    if(!address_f.includes(address)){
      this.addressForm.get('address')?.setValue(address)
    }
    if(!city_f.includes(city)){
      this.addressForm.get('city')?.setValue(city)
    }
    this.addressForm.get('postcode')?.setValue(normalizedPostcode);

  }
  checkBillingPostCode() {
    const postcode = this.contactForm.get('billingAddress')?.get('postcode')?.value;
    if (postcode) {
      const normalizedPostcode = postcode.trim().replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase();
        this.apiService.getPostCodeAzure(normalizedPostcode).subscribe((data: any) => {
            if (data["valid"] === false) {
                // Set form control as invalid
                this.contactForm.get('billingAddress')?.get('postcode')?.setErrors({ 'invalid': true });
                this.postCodeValidity = false;
            } else {
                // Clear any existing errors and set form control as valid
                this.contactForm.get('billingAddress')?.get('postcode')?.setErrors(null);
                this.postCodeValidity = true;
            }
        }, (error) => {
            // Handle API call error
            //console.error('Error occurred while validating postcode:', error);
        });
    }
}


  setBillingAddress(formatted_address:string){
    const postcode = this.addressForm.get('postcode')?.value
    const normalizedPostcode = postcode.trim().replace(/[^a-zA-Z0-9]/g, '').toUpperCase();   
    let splits = formatted_address.split(',')
    let address = splits[0]
    splits[splits.length - 2] =splits[splits.length - 2].trim()
    let city = splits[splits.length - 2].split(' ')[0]
    let address_f = this.contactForm.get('billingAddress')?.get('address')?.value;
    let city_f = this.contactForm.get('billingAddress')?.get('city')?.value;
    if(!address_f.includes(address)){ 
      this.contactForm.get('billingAddress')?.get('address')?.setValue(address);
    }
    if(!city_f.includes(city)){
      this.contactForm.get('billingAddress')?.get('city')?.setValue(city)
    }
    
    this.addressForm.get('postcode')?.setValue(normalizedPostcode);
  }
  updateSelectedAddress(event: Event) {
    this.selectedIndex = (event.target as HTMLSelectElement)?.selectedIndex;
  
    this.selected_adr = this.addressForm.get('addressSelect')?.value;
  
    if (this.selected_adr) {
      this.addressForm.get('city')?.setValue(this.selected_adr.city);
      this.addressForm.get('postcode')?.setValue(this.selected_adr.postcode);
      this.addressForm.get('address')?.setValue(this.selected_adr.address);
    }
  }
  
  confirmAddress(){
    this.checkPostCode();
    const newAddress: Address = {
      address: this.addressForm.get('address')?.value,
      city: this.addressForm.get('city')?.value,
      postcode: this.addressForm.get('postcode')?.value,
    };
    if(this.selected_adr){
      this.user.address[this.selectedIndex] = newAddress;
      this.address[this.selectedIndex] = newAddress;
    }
  }

  checkButtonToggle(){
    this.checkButton=true;
  }
  checkError(control:string){
    return this.addressForm.get(control)?.invalid;
  }

  cacheQuoteResult(){
    // Sends the current value of Quote ID,Panel and Offcut in Local Storage
  const panels=this.cForm.get('panels')?.value
  const offcuts=this.cForm.get('offcuts')?.value;
  const newAddress: Address = {
    address: this.addressForm.get('address')?.value,
    city: this.addressForm.get('city')?.value,
    postcode: this.addressForm.get('postcode')?.value,
  };
  localStorage.setItem('my_quoteId',this.quote.quoteId);
  localStorage.setItem('userId', this.user.userId )
  localStorage.setItem('offcuts', offcuts )
  localStorage.setItem('panels', panels )  
  localStorage.setItem('collection/delivery',this.radioForm.get('choice')?.value)
  if (this.selected_adr){
    localStorage.setItem('addressSelected',JSON.stringify(this.selected_adr))
  }else{
  localStorage.setItem('addressSelected',JSON.stringify(newAddress))
  }
  }
  goBack() {
    if (this.user){
    this.router.navigate(['/contact-us']);
    setTimeout(() => {
      this.router.navigate(['/customer/get-quote', this.quote.quoteId]);
    }, 100); 
    }
    else{
      this.router.navigate(['/contact-us']);
        setTimeout(() => {
      this.router.navigate(['/customer/get-quote']);
    }, 100);

    }
  }
  forgotPasswordPopUp() {
    // event.stopPropagation();
    // this.isSignIn = false;
    // this.isSignupOn = false;
    // this.isForgotPassword = true;
    this.openforgotPasswordModal(this.forgotPassword)

  }
  openforgotPasswordModal(template: TemplateRef<any>): void {
    // this.modalHeader=header;
    // this.modalMessage = message;
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }
  handleFpPassword(){
    
    this.apiService.forgotPassword(this.fpEmail).subscribe(data => {
      this.bsModalRef.hide();
    })
  }
  cancelReset(){
    this.bsModalRef.hide();
  }
  
  
}
