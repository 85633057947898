<div class="main-body" >
<form [formGroup]="deliveryForm" (ngSubmit)="onNext()" class="mb-5 pdl-60" style="width:85%">
  <h1 *ngIf="!collection">Delivery</h1>
  <h1 *ngIf="collection">Collection</h1>
  
  <div class="row mb-3">
        <div class="col-sm-2">
          <label for="dateInput">Needed By:<span class="required"></span></label>
        </div>
        <div class="col-sm-6">
          <input #dateInput [value]="defaultDate | date: 'yyyy-MM-dd'" type="date" class="standard-input" id="dateInput" formControlName="needBInput" [max]="maxDateString" [min]="defaultDate"
          >
        
          <span>&nbsp; &nbsp;(Leadtime <b>{{leadtime|| 7}} working days</b> from placing order)
          </span>
          <div *ngIf="deliveryForm.get('needBInput')?.hasError('weekend') && !deliveryForm.get('needBInput')?.hasError('pastDate') && !deliveryForm.get('needBInput')?.hasError('maxDateError') && !deliveryForm.get('needBInput')?.hasError('bankHolidayError')" class="text-danger mt-1">
            Sorry, collections/deliveries only available on weekdays.

          </div>
          <div *ngIf="getStatus('needBInput') && deliveryForm.get('needBInput')?.hasError('required')" class="text-danger mt-1" ngb-form-validation>
            Needed by is required
          </div>
            <div *ngIf="deliveryForm.get('needBInput')?.hasError('pastDate')" class="text-danger mt-1" ngb-form-validation>
             Invalid date. Please enter a date in the future.
            </div>
            <div *ngIf="deliveryForm.get('needBInput')?.hasError('maxDateError')" class="text-danger mt-1" ngb-form-validation>
              Sorry, order needs to be collected within 60 days.
             </div>
             <div *ngIf="deliveryForm.get('needBInput')?.hasError('bankHolidayError')" class="text-danger mt-1" ngb-form-validation>
              Sorry, collections/deliveries not available on Bank Holidays.
             </div>
             <div *ngIf="deliveryForm.get('needBInput')?.hasError('manualHolidayError') && !deliveryForm.get('needBInput')?.hasError('maxDateError')" class="text-danger mt-1" ngb-form-validation>
              Sorry, collections/deliveries not available on Company Holidays.
             </div>
        </div>  
       
     
    </div>
 
    <div *ngIf="!collection">
      <div class="row mb-3">
        <div class="col-sm-2">
            <label>Delivery Timing:</label>
        </div>
          <div class="col-sm-6">
            <select formControlName="deliveryTiming" class="standard-input">
               <!-- Options for delivery timing -->
                <option value="morning">Morning</option>
                <option value="afternoon">Afternoon</option>
                <option value="evening" selected>Evening</option>
            </select>
            <div *ngIf="getStatus('deliveryTiming')" class="text-danger mt-1" ngb-form-validation>
              Delivery Timings is required
            </div>
          </div>
          
      </div>
  </div>
  <div *ngIf="collection">
    <div class="row mb-3">
        <div class="col-sm-2">
            <label>Select Depot:<span class="required"></span></label>
        </div>
        <div class="col-sm-6">
          <select formControlName="depot" class="standard-input">
          <!-- Options for delivery timing -->
          <option [value]="0">{{Depo_0}}</option>
          <option [value]="1">{{Depo_1}}</option>
          </select>
          <div *ngIf="getStatus('depot')" class="text-danger mt-1" ngb-form-validation>
            Select a depot
              </div>
        </div>
        
    </div>
  </div>
    <div *ngIf="!collection">
      <div class="row mb-3">
        <div class="col-sm-2">
          <label>Delivery Instructions:</label>
        </div>
        <div class="col-sm-6">
          <textarea class="text-field" formControlName="instructionDetails"></textarea>
        </div>  
      </div>
    </div>
  
    <div>
      <div class="row mb-3">
        <div class="col-sm-2">
          <label>Purchase Order:</label>
        </div>
        <div class="col-sm-6">
          <input class="standard-input" type="text" formControlName="purchaseOrder">
          <div *ngIf="purchaseOrderFlag===true" class="text-danger" style="font-weight: bold;" >
            Purchase Order is required.
          </div>
        </div>  
        
      </div>
    </div>
  
    <div class="actions-btns">
      <button type="button"  class="btn btn btn-secondary btns-action margin-r-15" (click)="goToDetails()">Cancel</button>
      <button type="submit" [disabled]="deliveryForm.invalid" class="btn btn-primary btns-action">Next</button>
    </div>
  </form>
</div>
  