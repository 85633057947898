import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-blog-cutmelamine',
  standalone: true,
  imports: [],
  templateUrl: './blog-cutmelamine.component.html',
  styleUrl: './blog-cutmelamine.component.scss'
})
export class BlogCutmelamineComponent {
    constructor(private metadataService: MetadataService){
      
    }
    ngOnInit(): void {
      this.metadataService.setTitle('How To Cut Melamine Boards The Correct Way | Cutwrights ');
      this.metadataService.setDescription('Learn efficient techniques for cutting melamine board accurately and safely. Explore expert tips and tools for precise results in this article ');
    }

}
