<div class="main-body">
    <div id="loginAlertDiv" *ngIf="!closeAlert"><div class="alert alert-success"><a class="close" data-dismiss="alert" aria-label="close" (click)="closeAlert = !closeAlert">×</a><strong>Success :</strong> Successfully Sent Email</div></div>
    <ng-template #loading>
        <div class="loader-section">
            <app-loader></app-loader>
        </div>
    </ng-template>
    <ng-container *ngIf="(quotation$ |async) as quotation; else loading">
        <div *ngIf="(quote$ |async) as quote">
            <!-- «Start| Side bar» -->
            <div class="side-bar">

            </div>
            <!-- «End| Side bar» -->
            <div class="content-area">
                <div class="content-area-content">


                    <div class="up-content">
                        <span class="view-body">
                            <div *ngIf="isAdmin" class="item">
                                <div class="label">
                                    Customer Name:
                                </div>
                                <div class="value">
                                    {{quote.customerName}}

                                </div>
                            </div>
                            <div *ngIf="isAdmin" class="item">
                                <div class="label">
                                    Company Name:
                                </div>
                                <div class="value">
                                    {{quote.customerCompanyName}}

                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    Reference:
                                </div>
                                <div class="value">
                                    {{quote.jobId}}

                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    Job Name:
                                </div>
                                <div class="value">
                                    {{quote.name}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    Created at:
                                </div>
                                <div class="value">
                                    {{quote?.Id?.CreationTime| date: 'dd/MM/yy HH:mm'}}

                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    Panels Labelled:
                                </div>
                                <div class="value">
                                    {{quote.panelsNeedsLabelling?"Yes":"No"}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    Off-cuts Included:
                                </div>
                                <div class="value">
                                    {{quote.includeOffCuts?"Yes":"No"}}
                                </div>
                            </div>

                            <div class="item">
                                <div class="label">
                                    Total weight (Off-cuts Included):
                                </div>
                                <div class="value">
                                    {{quote.totalweight | number:'1.2-2'}}
                                </div>
                            </div>


                            <div class="item">
                                <div class="label">
                                    {{quote.collection?"Collection":"Delivery"}}
                                </div>
                                <div class="value">
                                    {{quote.collection ? "Yes" : quote.address.address + ', ' + quote.address.city + ', ' + quote.address.postcode}}

                                </div>
                            </div>




                            <div class="item">
                                <div class="label">
                                    Lead time:
                                </div>
                                <div class="value">
                                    {{quote.leadtime}} Working Days (From placement of Order)
                                </div>
                            </div>

                            <div *ngIf="quote.IsOffCutQuote" class="item off-cut-warning">
                                <div class="value">
                                   The Off-Cut/s included as part of this quote may also be used in other quotations we issue. Meaning they could be sold at any time resulting in your quotation expiring earlier than 30 days. If this happens your job will be requoted using the current boards and Off-Cut/s available. To ensure you secure the Off-Cut please proceed with the order as promptly as you can.
                                </div>      
                             </div>

                            <div class="item our-price">
                                <div class="label">
                                    Our price:
                                </div>
                                <div class="value">
                                    <span *ngIf="getStatus(quote) != 'NeedsReview' || isAdmin"> £{{truncateValue(quotation.totalnetamount)}} + VAT</span>
                                    <span *ngIf="getStatus(quote) == 'NeedsReview' && !isAdmin"> £0.00 + VAT</span>
                                </div>
                            </div>

                            <div class="item">
                                <div class="value">

                                </div>
                            </div>
                            <div *ngIf="quote.status != 6" class="buttons">
                                <input *ngIf="(designFile$ |async) as file" type="submit"
                                    name="Root$Main$ctl00$btnDownloadPattern" value="Download cutting patterns"
                                    (click)="download_file(file)" id="Main_ctl00_btnDownloadPattern"
                                    class="cutting-list-print">
                                <input type="submit" name="Root$Main$ctl00$btnPrintQuote" [value]="isOrder?'Print your Order':'Print your Quote'"
                                    (click)="navigateToQuote()"
                                     id="Main_ctl00_btnPrintQuote"
                                    class="cutting-list-print">
                                <input *ngIf="!isOrder && quote.status != 4" type="submit" (click)="makePayment(quotation.totalnetamount)" name="Root$Main$ctl00$btnPlaceTheOrder"
                                    value="Place the order"
                                    id="Main_ctl00_btnPlaceTheOrder">
                            </div>
                        </span>
                        <!-- «End| View Quote» -->
                    </div>
                    <div>

                        <!-- «Start| List: CostItem» -->
                        <h2 *ngIf="getStatus(quote) != 'NeedsReview' || isAdmin">{{isOrder?'Your order is based on':'Your quote is based on'}}</h2>
                        <div *ngIf="getStatus(quote) != 'NeedsReview' || isAdmin" class="list">
                            <div>
                                <table class="grid" cellspacing="0" currentsort="" id="Main_ctl01_gridList"
                                    style="border-collapse:collapse;">
                                    <tbody>
                                        <tr class="header-row">
                                            <th scope="col">Product description</th>
                                            <th scope="col">Unit Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Net Price</th>
                                            <th scope="col">VAT</th>
                                            <th scope="col">Total</th>
                                            <th *ngIf="isAdmin || impersonate===true" scope="col">Edit</th>
                                            <th *ngIf="isAdmin || impersonate===true" scope="col">Delete</th>
                                        </tr>
                                        <tr *ngFor="let detail of quotation.details">
                                            <td>
                                                {{detail.description}}
                                                <p *ngIf="findWarningByCode(detail.code)" style="color: red;">{{findWarningByCode(detail.code)}}</p>
                                            </td>
                                            <td>
                                                <span *ngIf="getStatus(quote) != 'NeedsReview' || isAdmin" id="Main_ctl01_gridList_ltrUnitPrice_0"
                                                    style="color:Black;">£{{truncateValue(detail.unitprice)}}</span>
                                            </td>
                                            <td>
                                                {{truncateValueQuantity(detail.quantity)}}
                                            </td>
                                            <td>
                                                <span *ngIf="getStatus(quote) != 'NeedsReview'|| isAdmin" id="Main_ctl01_gridList_ltrNetPrice_0"
                                                    style="color:Black;">£{{truncateValue(detail.netprice)}}</span>
                                            </td>
                                            <td>
                                                <span *ngIf="getStatus(quote) != 'NeedsReview'|| isAdmin" id="Main_ctl01_gridList_ltrVAT_0"
                                                    style="color:Black;">£{{truncateValue(detail.vat)}}</span>
                                            </td>
                                            <td>
                                                <span *ngIf="getStatus(quote) != 'NeedsReview'|| isAdmin" id="Main_ctl01_gridList_ltrTotal_0"
                                                    style="color:Black;">£{{truncateValue(detail.total)}}</span>
                                            </td>
                                            <td>
                                                <input type="image" name="Root$Main$ctl00$gridList$ctl02$btnEdit" *ngIf="checkIfDeletePossible(detail.code) && (isAdmin || impersonate===true)"
                                                  src="../../../assets/images/edit.png" alt="Edit" (click)="editExtraCostItem(detail.code)">
                                              </td>
                                            <td>
                                                <input type="image" *ngIf="checkIfDeletePossible(detail.code) && (isAdmin || impersonate===true)"
                                                  src="../../../assets/images/delete.png" alt="Delete" (click)="deleteItem(detail.code)" >
                                              </td>
                                        </tr>

                                        <tr class="footer">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>
                                                <b>
                                                    Grand Total
                                                </b>
                                            </td>
                                            <td>
                                               <span *ngIf="getStatus(quote) != 'NeedsReview' || isAdmin"> £{{getAmount(quotation.totalinvoiceamount.toString())}} </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="!isOrder && (isAdmin ||impersonate)" class="buttons">
                            <div *ngIf="getStatus(quote)!='Won'">
                            <input type="button" name="Root$Main$ctl04$btnBack" value="Add Extra Cost Item" (click)="openExtraCost()"
                            id="Main_ctl04_btnBack" class="back-button">
                            </div>
                        <input type="button" name="Root$Main$ctl04$btnMakeAChange" value="Send Email"
                            (click)="sendEmail()" id="Main_ctl04_btnMakeAChange">

                        </div>
                        <!-- «End| List: CostItem» --><input type="hidden"
                            name="Root$Main$ctl01$SelectedListItemIdHolder" id="Main_ctl01_SelectedListItemIdHolder">
                    </div>
                    <div>

                        <!-- «Start| View: Quote - Shaker door» -->
                        <div class="order-summary roman" >
                            <h3 class="bold-condensed">
                                Order Summary
                            </h3>
                            <span class="category">
                                Parts
                                <span class="value">
                                    {{quote.numberOfParts}}
                                </span>
                            </span>
                            <span class="category">
                                Shaker doors
                                <span class="value">
                                    {{getShakerDoorsC()}}
                                </span>
                            </span>
                            <span class="category">
                                DRAWER FRONTS
                                <span class="value">
                                    {{getShakerDrawerC()}}
                                </span>
                            </span>
                            <span class="category weight">
                                <p class="t_weight">Total weight (excl. offcuts)</p>
                                <span style="margin-bottom: 18px;" class="value">
                                    {{quote.weight | number:'1.2-2'}} kgs
                                </span>
                            </span>                            
                        </div>
                        <!-- «End| View: Quote - Shaker door» -->
                    </div>
                    <div>

                        <!-- «Start| List: Part - Cutting and edging list» -->
                        <h2>Your cutting and edging list</h2>
                        <div id="Main_ctl03_upList" isupdatepanel="true" *ngIf="quote.parts.length>0">
                            <div class="list">
                                <div>
                                    <table class="grid" cellspacing="0" currentsort="item.LineNumber"
                                        id="Main_ctl03_gridList" style="border-collapse:collapse;">
                                        <tbody>
                                            <tr class="header-row">
                                                <th scope="col"><a>Part</a>
                                                </th>
                                                <th scope="col">Material</th>
                                                <th scope="col"><a>L</a>
                                                </th>
                                                <th scope="col"><a>W</a>
                                                </th>
                                                <th scope="col"><a>Quantity</a>
                                                </th>
                                                <th scope="col"><a>L1</a>
                                                </th>
                                                <th scope="col"><a>L2</a>
                                                </th>
                                                <th scope="col"><a>W1</a>
                                                </th>
                                                <th scope="col"><a>W2</a>
                                                </th>
                                                <th scope="col"><a>Operations</a>
                                                </th>
                                                <th scope="col"><a>Name</a>
                                                </th>
                                                <th scope="col"><a>Needs
                                                        priming</a></th>
                                            </tr>
                                            <tr *ngFor="let part of quote.parts; let i = index">
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{part.materialDescription}}
                                                </td>
                                                <td>
                                                    {{part.length}}
                                                </td>
                                                <td>
                                                    {{part.width}}
                                                </td>
                                                <td>
                                                    {{part.quantity}}
                                                </td>
                                                <td>
                                                    {{part.l1Description}}
                                                </td>
                                                <td>
                                                    {{part.l2Description}}

                                                </td>
                                                <td>
                                                    {{part.w1Description}}

                                                </td>
                                                <td>
                                                    {{part.w2Description}}

                                                </td>
                                                <td>
                                                    <a id="Main_ctl03_gridList_btnViewDrillingSummary_0"></a>
                                                </td>
                                                <td>
                                                    {{part.partComment}}
                                                </td>
                                                <td>
                                                    {{part.primed?"Yes":"No"}}
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <input type="hidden" name="Root$Main$ctl03$SelectedListItemIdHolder"
                                id="Main_ctl03_SelectedListItemIdHolder">
                        </div>
                        <div  *ngIf="quote.parts.length<= 0"  style="border-bottom: 1px solid #ccc;">
                            <br>
                            <h4>There are no parts to display.</h4>
                        </div>

                        <!-- «Start| List: Part - Cutting and edging list» -->
                        <br>
                        <h2>Your shaker door list</h2>
                        <div id="Main_ctl04_upList" isupdatepanel="true" *ngIf="quote.shakers.length>0">
                            <div class="list">
                                <div>
                                    <!-- <table class="grid" cellspacing="0" currentsort="item.LineNumber" id="Main_ctl04_gridList"
                                style="border-collapse:collapse;">
                                <tbody>

                                    <tr class="empty-template">
                                        <td colspan="8">
                                            There are no parts to display.
                                        </td>
                                    </tr>
                                </tbody>
                            </table> -->
                                    <table class="grid" cellspacing="0" currentsort="item.LineNumber"
                                        id="Main_ctl03_gridList" style="border-collapse:collapse;">
                                        <tbody>
                                            <tr class="header-row">
                                                <th scope="col"><a
                                                        href="">Part</a>
                                                </th>
                                                <th scope="col">Material</th>
                                                <th scope="col"><a
                                                        href="">H</a>
                                                </th>
                                                <th scope="col"><a
                                                        href="">W</a>
                                                </th>
                                                <th scope="col"><a
                                                        href="">Quantity</a>
                                                </th>
                                                <th scope="col"><a
                                                        href="">Operations</a>
                                                </th>
                                                <th scope="col"><a
                                                        href="">Name</a>
                                                </th>
                                                <th scope="col"><a
                                                        href="">Needs
                                                        priming</a>
                                                </th>
                                            </tr>
                                            <tr *ngFor="let part of quote.shakers; let i = index">
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{getType(part)}}
                                                </td>
                                                <td>
                                                    {{part.height}}
                                                </td>
                                                <td>
                                                    {{part.width}}
                                                </td>
                                                <td>
                                                    {{part.quantity}}
                                                </td>
                                                <td>
                                                    <a *ngIf="part.totalnumberofhingeholes > 0 || part.totalnumberofmidrails" (click)="openPopUp(quote,i)">{{getLinkName(part)}}</a>
                                                </td>
                                                <td>
                                                    {{part.name}}

                                                </td>
                                                <td>
                                                    {{part.primed?"Yes":"No"}}
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <input type="hidden" name="Root$Main$ctl04$SelectedListItemIdHolder"
                                id="Main_ctl04_SelectedListItemIdHolder">
                        </div>
                        <div  *ngIf="quote.shakers.length<= 0"  style="border-bottom: 1px solid #ccc;">
                            <br>
                            <h4>There are no doors or drawers to display.</h4>
                        </div>
                        <div class="buttons">
                            <input type="button" name="Root$Main$ctl04$btnBack" value="Back" (click)="goToQuotes()"
                                id="Main_ctl04_btnBack" class="back-button">
                            <input *ngIf="(!isOrder && ((!isAdmin && quote.status != 6) || (isAdmin || impersonate)))" (click)="makeAChange()" type="submit" name="Root$Main$ctl04$btnMakeAChange" value="Make a change"
                                id="Main_ctl04_btnMakeAChange">
                            <input *ngIf="!isOrder && quote.status != 4 && quote.status != 6" type="submit" name="Root$Main$ctl04$btnPlaceTheOrder" value="Place the order"
                            (click)="makePayment(quotation.totalnetamount)" id="Main_ctl04_btnPlaceTheOrder">
                                <input *ngIf="quote.status == 6 && isAdmin && !isOrder" type="button" name="Root$Main$ctl04$btnPlaceTheOrder" value="Resolve"
                                 (click)="resolve()" id="Main_ctl04_btnPlaceTheOrder">
                            <input type="submit" name="Root$Main$ctl04$btnPrintYourCuttingList"
                                value="Print your cutting list" (click)="generatePDF()"
                                id="Main_ctl04_btnPrintYourCuttingList" class="cutting-list-print">
                        </div>
                        <!-- «End| List: Part - Cutting and edging list» -->
                    </div>
                    <div>

                        <span class="view-body">
                            <div class="item">
                                <div class="label">
                                    Comment:
                                </div>
                                <div class="value">
                                    {{quote.comment}}
                                </div>

                                <div id="Main_ctl05_pnlHistory">

                                    <div style="float:left">
                                        <span id="Main_ctl05_lblHistory"></span>
                                    </div>

                                </div>

                            </div>
                        </span>
                    </div>

                </div>
            </div>
            <div class="floatfix"></div>
        </div>
    </ng-container>
</div>
<ng-template #template>
    <div class="door-modal-popup">
        <button class="close-btn" (click)="close()">x</button>
        <form class="add-a-door" [formGroup]="this.sformGroup">
            <div class="row">
                <div class="col-sm-12">
                    <h1>Add a door</h1>
                </div>

                <div class="col-sm-6">
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Door name:
                        </div>
                        <div class="col-sm-6">
                            <input type="text" formControlName="name"  />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Height:
                        </div>
                        <div class="col-sm-6">
                            <input type="number" formControlName="height" value="30" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Width:
                        </div>
                        <div class="col-sm-6">
                            <input type="number" formControlName="width" value="40" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Quantity:
                        </div>
                        <div class="col-sm-6">
                            <input type="number" formControlName="quantity" value="5" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Needs priming:
                        </div>
                        <div class="col-sm-6">
                            <input type="checkbox" formControlName="primed" />
                        </div>
                    </div>

                    <div class="row mb-3 mt-3">
                        <div class="col-sm-12">
                            <h1>Mid rails</h1>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Number of mid rails:
                        </div>
                        <div class="col-sm-6">
                            <select formControlName="totalnumberofmidrails">
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                    </div>

                    <div formArrayName="midrails">
                        <div *ngFor="let midrail of getMidrailControls(this.sformGroup); let i = index"
                            class="row mb-3">
                            <div class="col-sm-6">
                                Mid rail {{i+1}} Position:
                            </div>
                            <div [formGroupName]="i" class="col-sm-6">
                                <input type="number" formControlName="position" />
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3 mt-3">
                        <div class="col-sm-12">
                            <h1>Hinges</h1>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Drill holes for 35mm cup hinges:
                        </div>
                        <div class="col-sm-6">
                            <input class="checkbox-shaker" type="checkbox" formControlName="hingeholes35mm" />
                        </div>
                    </div>
                    <div [hidden]="sformGroup.get('hingeholes35mm')?.value == false">
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                Use Blum Inserta hinges:
                            </div>
                            <div class="col-sm-6">
                                <input class="checkbox-shaker" type="checkbox" formControlName="useinsertahinges" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                Hinge side:
                            </div>
                            <div class="col-sm-6">
                                <input class="checkbox-shaker" type="radio" [value]="0" formControlName="hingeside" />
                                Left
                                <input class="checkbox-shaker" type="radio" [value]="1" formControlName="hingeside" />
                                Right
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                Number of hinges:
                            </div>
                            <div class="col-sm-6">
                                <select formControlName="totalnumberofhingeholes">
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                        </div>
                        <div formArrayName="hinges">
                            <div *ngFor="let hinge of getHingeControls(this.sformGroup); let i = index"
                                class="row mb-3">
                                <div class="col-sm-6">
                                    Hinge {{i+1}} Y:
                                </div>

                                <div [formGroupName]="i" class="col-sm-6">
                                    <input (focus)="setFocus('h'+i)" (focusout)="resetFocus('h'+i)" type="number" formControlName="y" />
                                </div>
                            </div>

                        </div>
                        <!-- <div class="row mb-3">
               <div class="col-sm-6">
                 Hinge 2 Y:
               </div>
               <div class="col-sm-6">
                 <input type="text" formControlName="hinges[1]" />
               </div>
             </div> -->
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                Drilling distance:
                            </div>
                            <div class="col-sm-6">
                                <input type="text" formControlName="drillingdistance" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <img [src]="imageSrc" />
                </div>

                <!-- <div class="com-sm-12 text-center mt-4">
                    <input type="button" value="Save and close" (click)="close()" />
                </div> -->
            </div>
        </form>

    </div>
</ng-template>
<ng-template #ExtraCostTemplate>
    <div class="door-modal-popup my-modal">
        <button class="close-btn" (click)="close()">x</button>
    <form [formGroup]="extraCostForm" (ngSubmit)="submitExtraCost()">

        <div class="form-group">
            <label for="details">Details:</label>
            <input type="text" id="details" formControlName="description" class="form-control" onkeydown="return event.key !== '|'" oninput="this.value = this.value.replace(/\|/g, '')"
            required />
        </div>
        <div class="form-group">
          <label for="unitPrice">Unit Price:</label>
          <input type="number" id="unitPrice" formControlName="unitPrice" class="form-control" />
        </div>
      
        <div class="form-group">
          <label for="departmentCode">Department Code:</label>
          <select id="departmentCode" formControlName="departmentCode" class="form-control">
            <option *ngFor="let item of departmentCode$ | async" [value]="item.code">{{item.name}}</option>
            <!-- Add department code options dynamically if needed -->
          </select>
        </div>
        <div class="form-group">
          <label for="nominalCode">Nominal Code:</label>
          <select id="nominalCode" formControlName="nominalCode" class="form-control">
            <option *ngFor="let item of nominalCode$ | async" [value]="item.code">{{item.name}}</option>
            <!-- Add nominal code options dynamically if needed -->
          </select>
        </div>
      
        <button 
          type="submit" 
          [ngClass]="{'cutting-list-buttons': true, 'bold-condensed': true, 'grey-button': !extraCostForm.valid}" 
          [disabled]="!extraCostForm.valid">
          Submit
        </button>

      </form>
    </div>
</ng-template>