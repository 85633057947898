
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { Component, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Order, eOrderStatus } from 'src/app/models/cut-wrights.model';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent {
  user!:any;

  reversePaymentType: { [key: number]: string } = {
    0: 'Card',
    1: 'Cash',
    2: 'Cheque',
    3: 'BankTransfer',
    4: 'WriteOff'
  };
  payments!:any;
  admin!:any
  selectedOrder = ""
  bsModalRef!: BsModalRef;
  myForm:FormGroup
  selectedOrderD!:any;
  pOrders! : any[];
  impersonate
  rOrders!: any[];
  constructor(private apiService: EndpointApiService, private formBuilder: FormBuilder, private modalService: BsModalService,private fb: FormBuilder){
    this.impersonate = localStorage.getItem('impersonate')
    if(this.impersonate == "true"){
      this.admin = localStorage.getItem('admin')
      this.admin = JSON.parse(this.admin as string)
    }
    this.myForm = this.fb.group({
      QuoteId: [null, Validators.required],
      Amount: [null, [Validators.required]],
      IsPayment: [true, Validators.required],
      PaymentType: [null, Validators.required],
      Comment: [null],
      CreatedOn: [new Date(),Validators.required],
      RecordedBy:[this.admin.email]
    });
    this.myForm.get('Amount')?.disable()
    const token = localStorage.getItem('token');
    const userString = localStorage.getItem('user');
    if (token) {
      this.user = JSON.parse(userString as string)
      this.getCustomerPayments(); 
      this.getCustomerOrders()
    }
  }
  getCustomerPayments() {
    this.apiService.getCustomerPayments(this.user.userId).subscribe(data => {
      this.payments = data;
    })
  }
  openRecordAPaymentPopup(templateRef: TemplateRef<any>) {
    this.myForm.get('CreatedOn')?.setValue(new Date());
    this.myForm.get('IsPayment')?.setValue(true);
    this.bsModalRef = this.modalService.show(templateRef);
  }
  closeModal() {
    this.bsModalRef.hide();
    this.selectedOrder = ""
    this.myForm.reset()
    this.myForm.get('CreatedOn')?.setValue(new Date());
    this.myForm.get('IsPayment')?.setValue(true);
    this.myForm.get('RecordedBy')?.setValue(this.admin.email)
  }
  onSubmit(name:string) {
    if (this.myForm.valid) {
      const formData = this.myForm.value;
      
      if(name == 'refund'){
        formData.IsPayment= false
        formData.CreatedOn = (new Date()).toISOString();
        formData.OrderId = this.rOrders.find(order => order.quoteId === formData.QuoteId).orderGuid
      } else {
        formData.OrderId = this.pOrders.find(order => order.quoteId === formData.QuoteId).orderGuid
      }
      // 
      this.apiService.makeManualPayment(this.myForm.value).subscribe(data =>{
        this.getCustomerPayments(); 
        this.getCustomerOrders();
        this.closeModal()
      })
    }
  }
  getCustomerOrders(){
    this.apiService.getCustomerOrders(this.user.userId).subscribe((data:any) => {
      this.pOrders = ((data as Order[]).map(order => { if(order.paymentId == null || order.status == eOrderStatus.WaitingForAuthorization){return {"jobId":order.jobReference, "quoteId" : order.quoteId,"orderGuid":order.orderId,"totalinvoiceamount":order.totalinvoiceamount}} return null}).filter(orderId => orderId !== null) as any[])
      this.rOrders = ((data as Order[]).map(order => { if(order.status != eOrderStatus.Cancelled && order.refundId == null && order.authorised == true){return {"jobId":order.jobReference, "quoteId" : order.quoteId,"orderGuid":order.orderId,"totalinvoiceamount":order.totalinvoiceamount}} return null}).filter(orderId => orderId !== null) as any[])
    })
  }
  getSelectedOrderDetails(type:string){
    if (type == 'payment'){
      return this.pOrders.find(order => order.quoteId == this.selectedOrder )
    }
    return this.rOrders.find(order => order.quoteId == this.selectedOrder )
  }
  setSelectedOrder(type:string){
    const formData = this.myForm.value;
    this.selectedOrder = formData.QuoteId
    let t_o = this.getSelectedOrderDetails(type)
    this.myForm.get('Amount')?.setValue(this.truncateValue(t_o.totalinvoiceamount))
    this.selectedOrderD = t_o
  }
  truncateValue(value: number): string {
    return this.apiService.truncateDecimal(value);
  }
}
