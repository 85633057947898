import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlSerializer } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { BoardRangeComponent } from "./board-range/board-range.component";
import { FaqComponent } from "./faq/faq.component";
import { GetQuoteComponent } from "./get-quote/get-quote.component";
import {
  materialTagsResolver,
  materialsResolver,
} from "../service/endpoint-api.service";
import { QuoteResultComponent } from "./quote-result/quote-result.component";
import { MyQuoteComponent } from "./my-quote/my-quote.component";
import { AuthGuard } from "../shared/auth-gaurds/login-gaurd";
import { QuoteDetailsComponent } from "./quote-details/quote-details.component";
import { PaymentComponent } from "./payment/payment.component";
import { PaymentConfirmComponent } from "./payment-confirm/payment-confirm.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { OrderListComponent } from "./order-list/order-list.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { MyAccountComponent } from "./my-account/my-account.component";
import { ShakerDoorComponent } from "./shaker-door/shaker-door.component";
import { ConfirmQuoteSubmissionComponent } from "./confirm-quote-submission/confirm-quote-submission.component";
import { InvoiceReceiptComponent } from "./invoice-receipt/invoice-receipt.component";
import { CustomerLayoutComponent } from "./customer-layout/customer-layout.component";
import { DeliveryComponent } from "./delivery/delivery.component";
import { CuttingComponent } from "./cutting/cutting.component";
import { EdgingComponent } from "./edging/edging.component";
import { DrillingComponent } from "./drilling/drilling.component";
import { OthersComponent } from "./others/others.component";
import { ChooseYourBoardsComponent } from "./choose-your-boards/choose-your-boards.component";
import { ChooseYourBoardsDetailsComponent } from "./choose-your-boards-details/choose-your-boards-details.component";
import { EnquiryComponent } from "./enquiry/enquiry.component";
import { DecorativeBoardComponent } from "./decorative-board/decorative-board.component";
import { HollowBoardComponent } from "./hollow-board/hollow-board.component";
import { MdfBoardComponent } from "./mdf-board/mdf-board.component";
import { MelamineBoardComponent } from "./melamine-board/melamine-board.component";
import { PlywoodBoardComponent } from "./plywood-board/plywood-board.component";
import { VeneeredBoardComponent } from "./veneered-board/veneered-board.component";
import { ShowRoomComponent } from "./show-room/show-room.component";
import { BlogComponent } from "./blog/blog.component";
import { BlogOfferComponent } from "./blog-offer/blog-offer.component";
import { BlogReceptionComponent } from "./blog-reception/blog-reception.component";
import { BlogRebrandingComponent } from "./blog-rebranding/blog-rebranding.component";
import { BlogMessageComponent } from "./blog-message/blog-message.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ChooseBoardViewAllComponent } from "./choose-board-view-all/choose-board-view-all.component";
import { CustomUrlSerializerService } from "../shared/CustomUrlSerializerService";
import { BlogCutmdfComponent } from "./blog-cutmdf/blog-cutmdf.component";
import { BlogCutmelamineComponent } from "./blog-cutmelamine/blog-cutmelamine.component";
import { BlogMdftypeComponent } from "./blog-mdftype/blog-mdftype.component";
import { BlogPaintmdfComponent } from "./blog-paintmdf/blog-paintmdf.component";
import { BlogSealmdfComponent } from "./blog-sealmdf/blog-sealmdf.component";
import { BlogKitchencabinateComponent } from "./blog-kitchencabinate/blog-kitchencabinate.component";
import { BlogKitchenhingeComponent } from "./blog-kitchenhinge/blog-kitchenhinge.component";
import { BlogTricoyaComponent } from "./blog-tricoya/blog-tricoya.component";

const routes: Routes = [
  {
    path: "",
    component: CustomerLayoutComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
      },      
      {
        path: "board-range",
        component: BoardRangeComponent,
      },
      {
        path: "delivery",
        component: DeliveryComponent,
      },
      {
        path: "cnc-cutting",
        component: CuttingComponent,
      },
      {
        path: "edge-banding",
        component: EdgingComponent,
      },
      {
        path: "cnc-drilling-service",
        component: DrillingComponent,
      },
      {
        path: "other-services",
        component: OthersComponent,
      },
      {
        path: "faq",
        component: FaqComponent,
      },
      {
        path: "contact-us",
        component: ContactUsComponent,
      },
      {
        path: "choose-your-boards",
        component: ChooseYourBoardsComponent,
        resolve: {
          material_tags: materialTagsResolver,
        },
      },
      {
        path: "choose-your-boards-viewall",
        component: ChooseBoardViewAllComponent,
        resolve: {
          material_tags: materialTagsResolver,
        },
      },
      {
        path: "choose-your-boards/:photoId",
        component: ChooseYourBoardsDetailsComponent,
        resolve: {
          material_tags: materialTagsResolver,
        },
      },
      {
        path: "enquiry",
        component: EnquiryComponent,
      },
      {
        path: "decorative-board",
        component: DecorativeBoardComponent,
      },
      {
        path: "hollow-board",
        component: HollowBoardComponent,
      },
      {
        path: "mdf-board",
        component: MdfBoardComponent,
      },
      {
        path: "melamine-board",
        component: MelamineBoardComponent,
      },
      {
        path: "plywood-board",
        component: PlywoodBoardComponent,
      },
      {
        path: "veneered-board",
        component: VeneeredBoardComponent,
      },
      {
        path: "show-room",
        component: ShowRoomComponent,
      },
      {
        path: "blog",
        component: BlogComponent,
      },
     
      {
        path: "blog/how-to-cut-mdf",
        component: BlogCutmdfComponent,
      },    
      {
        path: "blog/cutting-melamine-board",
        component: BlogCutmelamineComponent,
      },        
      {
        path: "blog/types-of-mdf-wood",
        component: BlogMdftypeComponent,
      },    
      {
        path: "blog/how-to-paint-mdf",
        component: BlogPaintmdfComponent,
      },    
      {
        path: "blog/how-to-seal-mdf-edges",
        component: BlogSealmdfComponent,
      },    
      {
        path: "blog/how-to-adjust-kitchen-cabinet-hinges",
        component: BlogKitchencabinateComponent,
      },    
      {
        path: "blog/what-hinges-for-kitchen-cabinets",
        component: BlogKitchenhingeComponent,
      },   
      {
        path: "blog/what-is-tricoya-mdf",
        component: BlogTricoyaComponent,
      },

      {
        path: "customer",
        children: [
          {
            path: "get-quote",

            component: GetQuoteComponent,
            resolve: {
              materials: materialsResolver,
            },
          },
          {
            path: "get-quote/:quoteId",

            component: GetQuoteComponent,
            resolve: {
              materials: materialsResolver,
            },
          },
          {
            path: "submit/:quoteId",
            component: QuoteResultComponent,
          },
          {
            path: "my-quote/:userId",
            component: MyQuoteComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "quote-details/:quoteId",
            component: QuoteDetailsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "order-details/:quoteId",
            component: QuoteDetailsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "payment/:quoteId",
            component: PaymentComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "payconfirm/:quoteId",
            component: PaymentConfirmComponent,
            canActivate: [AuthGuard],
          },

          {
            path: "order-list/:userId",
            component: OrderListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "invoices/:userId",
            component: InvoicesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "my-account/:userId",
            component: MyAccountComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "shaker-door",
            component: ShakerDoorComponent,
          },
          {
            path: "submit-result",
            component: ConfirmQuoteSubmissionComponent,
          },
          {
            path: "payment-result",
            component: PaymentConfirmComponent,
          },
          {
            path: "invoice-receipt/:quoteId/:type",
            component: InvoiceReceiptComponent,
          },
        ],
      },
    ],
  },
  //{path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [
  ],
  exports: [RouterModule],
  
})
export class CustomerRoutingModule {}
