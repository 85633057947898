import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-blog-kitchencabinate',
  standalone: true,
  imports: [],
  templateUrl: './blog-kitchencabinate.component.html',
  styleUrl: './blog-kitchencabinate.component.scss'
})
export class BlogKitchencabinateComponent {
  constructor(private metadataService: MetadataService){
      
  }
  ngOnInit(): void {
    this.metadataService.setTitle('How To Correctly Adjust Kitchen Cabinet Hinges | Cutwrights');
    this.metadataService.setDescription('Master the art of adjusting kitchen cabinet hinges for perfect alignment. Follow our step-by-step guide to ensure smooth, even cabinet doors');
  }

}
