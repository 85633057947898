import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-blog-sealmdf',
  standalone: true,
  imports: [],
  templateUrl: './blog-sealmdf.component.html',
  styleUrl: './blog-sealmdf.component.scss'
})
export class BlogSealmdfComponent {
  constructor(private metadataService: MetadataService){
      
  }
  ngOnInit(): void {
    this.metadataService.setTitle('The Importance Of Sealing MDF Edges Correctly | Cutwrights');
    this.metadataService.setDescription('Learn why sealing MDF edges is crucial for durability and finish. Discover effective techniques to seal MDF edges correctly for lasting results.');
  }
}
