import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-blog-paintmdf',
  standalone: true,
  imports: [],
  templateUrl: './blog-paintmdf.component.html',
  styleUrl: './blog-paintmdf.component.scss'
})
export class BlogPaintmdfComponent {
  constructor(private metadataService: MetadataService){
      
  }
  ngOnInit(): void {
    this.metadataService.setTitle('How To Paint Your MDF Boards The Right Way | Cutwrights ');
    this.metadataService.setDescription('Discover the expert techniques and tips for painting MDF boards flawlessly. Learn the step-by-step process for a perfect finish. Read more');
  }

}
