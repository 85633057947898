import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-blog-kitchenhinge',
  standalone: true,
  imports: [],
  templateUrl: './blog-kitchenhinge.component.html',
  styleUrl: './blog-kitchenhinge.component.scss'
})
export class BlogKitchenhingeComponent {
  constructor(private metadataService: MetadataService){
      
  }
  ngOnInit(): void {
    this.metadataService.setTitle('What Hinges Should I Use For My Kitchen Cabinets | Cutwrights');
    this.metadataService.setDescription('Find the best hinges for your kitchen cabinets. Explore different types and learn which hinges offer the best fit and functionality for your needs');
  }

}
